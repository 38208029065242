import React from "react"
import Header from "../header/header"
import Modal from "../header/modal"
import Profile from "./Profile"
import CreatePost from './CreatePost'
import Tab from './Tabs'
class index extends React.Component{
    render(){
        return(
            <React.Fragment>
             <Header />
             <Modal />
             <Profile />
             <CreatePost />
             <Tab />
            </React.Fragment>
        )
    }
}
export default(index)