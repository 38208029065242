import React, { Component } from 'react';
import MakerModal from './MakerModal';
import Makersocial from './CompanyInformation';
import Makerconfirm from './MakerConfirm'
import Makersuccess from './MakerSuccess';

export class MakerForm extends React.Component {
    state = {
        step: 1,
        name: '',
        role: '',
        location: '',
        city:'',
        topics:'',
        companyname: '',
        founded: '',
        industry: '',
        code: ''
    };

    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    };

    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    };

    inputChange = input => e => {
        this.setState({
            [input]: e.target.value
        });
    };

    render() {
        const { step } = this.state;
        const { name, role, location, city,topics, companyname, founded, industry,code } = this.state;
        const values = { name, role, location,city,topics, companyname, founded, industry,code };

        switch (step) {
            case 1:
                return (
                    <MakerModal
                        nextStep={this.nextStep}
                        inputChange={this.inputChange}
                        values={values}
                    />
                );
            case 2:
                return (
                    <Makersocial
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        inputChange={this.inputChange}
                        values={values}
                    />
                );
            case 3:
                return (
                    <Makerconfirm
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        values={values}
                    />
                );
            case 4:
                return (
                    <Makersuccess />
                );
        }
    }
}

export default MakerForm
