import React from "react"
import {Link } from 'react-router-dom'
class getStarted extends React.Component{
    render() {
        return(
            <div className="styl-get-section">
            <div className="container">
                <div className="styl-get-heading">
                    <h1>GET STARTED</h1>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card styl-get-card">
                            <img className="card-img-top styl-get-img get-img-mrgn-bottom" src="./assets/img/main-images/Professional.png" alt="Card image cap" />
                            <div className="card-body">
                            {/* <a className="styl-get-btnp" href="/crud">PROFESSIONAL</a>  */}
                            <Link to="/crud" className="styl-get-btnp">PROFESSIONAL</Link>
                            </div>
                        </div>
                    </div>
        
                    <div className="col-md-4">
                        <div className="card styl-get-card">
                            <img className="card-img-top styl-get-img2 get-img-mrgn-bottom" src="./assets/img/main-images/BULB.png" alt="Card image cap" />
                            <div className="card-body">
                            {/* <a className="styl-get-btn" href="/crud">START UP</a> */}
                            <Link to="/crud" className="styl-get-btn">START UP</Link>
                            </div>
                        </div>
                    </div>
        
                    <div className="col-md-4">
                        <div className="card styl-get-card">
                            <img className="card-img-top styl-get-img3 get-img-mrgn-bottom" src="./assets/img/main-images/strudents-2.png" alt="Card image cap" />
                            <div className="card-body">
                                {/* <a className="styl-get-btn" href="/crud">STUDENT</a> */}
                                <Link to="/crud" className="styl-get-btn">STUDENT</Link>
                            </div>
                        </div>
                    </div>
        
                    <div className="row">
        
                        <div className="col-md-6">
                            <div className="card styl-get-card-investor get-img-mrgn-bottom">
                                <img className="card-img-top styl-get-img-investor" src="./assets/img/main-images/Investor.png" alt="Card image cap" />
                                <div className="card-body">
                                {/* <a className="styl-get-btn-investor" href="/crud">INVESTOR</a> */}
                                <Link to="/crud" className="styl-get-btn">INVESTOR</Link>
                                </div>
                            </div>
                        </div>
        
                        <div className="col-md-6">
                            <div className="card styl-get-card-challenges get-img-mrgn-bottom">
                                <img className="card-img-top styl-get-challenge-img" src="./assets/img/main-images/chall-3.png" alt="Card image cap" />
                                <div className="card-body">
                                    {/* <a className="styl-get-chalenge-btn" href="/crud">CHALLANGES</a> */}
                                    <Link to="/crud" className="styl-get-chalenge-btn">CHALLANGES</Link>
                                </div>
                            </div>
                        </div>
        
        
                    </div>
        
                </div>
        
            </div>
        </div>

        
        );
    }
}
export default (getStarted)