import React from "react"
class ideacards extends React.Component{
    render() 
    {
        return(
            <div className="styl-section"> 
            <div className="container">
                <div className="row styl-idea-row">
                    <div className="card text-center styl-idea-card">
                        <div className="card-header">
                         <h1 className="styl-home-heading">WHAT'S YOUR IDEA</h1>
                        </div>
                        <div className="card-body">
                         <img src="./assets/img/main-images/IDEA.png" className="styl-idea-img" alt="" />
                        </div>
                      </div>
                </div>
            </div>
    
        </div> 	
  


        )
    }
}
export default (ideacards)