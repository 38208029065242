import React from "react"

class getServices extends React.Component {
    render() {
        return (
            <div className="styl-get-section">
            <div className="container">
                <div className="row mrgn-top-row">
                <div className="col-md-3">
                          <img className="card-img-top" src="./assets/img/main-images/Component 83 – 1.png" alt="Card image cap" />
                    </div>
                    <div className="col-md-3">
                            <img className="card-img-top" src="./assets/img/main-images/Component 77 – 6.png" alt="Card image cap" />
                    </div>
                    <div className="col-md-3">
                            <img className="card-img-top" src="./assets/img/main-images/Component 82 – 1.png" alt="Card image cap" />
                    </div>
                    <div className="col-md-3">
                            <img className="card-img-top" src="./assets/img/main-images/comd (3).png" alt="Card image cap" />
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default getServices;