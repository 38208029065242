import React, { Component } from 'react'

export class CompanyInformation extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const { values, inputChange } = this.props;

        return (
            <div className="styl-userProfile-Section">
                 <div className="container-fluid">
             <div className="row">
                 <div className="col-md-12">
                 <div className="form-container step-form-container">
                 <div className="container">
                     <div className="row">
                         <div className="col-md-2">
                         <i class="fas fa-building styl-company-logo"></i>
                        </div>
                         <div className="col-md-10">
                         <div className="upload-btn-wrapper">
                            <button className="btn upload-btn-maker">
                             UPLOAD COMPANY LOGO
                             </button>
                            <input type="file" name="myfile" />
                            </div> 
                            <p className="styl-maker-p">Image resolution:Min 500 x 500 px <br /> & Max 4096 x 4096 px</p>                        
                            </div>
                      </div>
                     </div>
                <div className="form-group">
                    <label htmlFor="name" className="lbl-color-maker">Company Name</label>
                    <input type="text" className="form-control styl-maker-inputs" name="companyname" onChange={inputChange('companyname')} value={values.companyname} />
                </div>
                <div className="form-group">
                    <label htmlFor="name" className="lbl-color-maker">Founded</label>
                    <input type="text" className="form-control styl-maker-inputs" name="founded" onChange={inputChange('founded')} value={values.founded} />
                </div>
                <div className="form-group">
                    <label htmlFor="phone" className="lbl-color-maker">Industry Sector</label>
                    <select className="form-control styl-maker-inputs" name="location" onChange={inputChange('industry')} value={values.industry}>
                        <option>a</option>
                        <option>b</option>
                        <option>c</option>
                    </select>                </div>
               
                    <div className="form-group">
                    <label htmlFor="name" className="lbl-color-maker">SIC Code</label>
                    <input type="text" className="form-control styl-maker-inputs" name="code" onChange={inputChange('code')} value={values.code} />
                </div>
                <br />
                <div className="row">
                    <div className="col-6">
                        <button className="btn btn-danger styl-continue-btn" onClick={this.back}>Back</button>
                    </div>
                    <div className="col-6 text-right">
                        <button className="btn btn-primary styl-continue-btn" onClick={this.continue}>Continue</button>
                    </div>
                </div>
            </div>
                 </div>
             </div>
         </div>
           </div>
        
        )
    }
}

export default CompanyInformation
