import React from "react"
import {Link } from 'react-router-dom'

class Profile extends React.Component {

    render() {
        return (
<div className="styl-userProfile-Section">
    <div className="container-fluid align-main-profile-Container">

        <div className="row mrgn-bth-container">

            <div className="col-md-8 styl-user-profile-container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="align-profile-img">
                            <img src="./assets/img/main-images/user.png" />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="align-user-profile-heading">
                                    <h1 className="styl-userprofile-heading">James Franklin  <i className="fas fa-edit styl-userprofile-icon"></i></h1>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="styl-user-profile-followtab">
                                    <span className="follow-span1">101</span> <span className="follow-span2">Followers</span>
                                </div>
                            </div>
                            <div className="styl-user-profile-followtab2">
                                <span> 
                                           <img src="./assets/img/main-images/usa.png"/>
                                           </span>
                                <span className="follow-span-name">Founder,CEO Open AI</span>
                            </div>
                            <p className="styl-usr-profile-content">The conversations that customers have with businesses matter a lot. They can shape customers’ opinions and their dedication to a brand. Customer service representatives are only human, so their responses to customer queries can vary.
                                <br />
                                <br /> A chatbot, however, will always stick to the rules that are set for it. It will always respond in the exact same manner. Chatbots can also be programmed to speak multiple languages, allowing companies to reach more customers, and they can be available 24/7.
                            </p>

                        </div>
                    </div>
                </div>
                <div className="row styl-icon-row">
                    <div className="col-md-8">
                        <i className="fas fa-expand  styl-userprofile-social"></i>
                        <i className="fab fa-facebook-f styl-userprofile-social"></i>
                        <i className="fab fa-twitter styl-userprofile-social"></i>
                        <i className="fab fa-linkedin-in styl-userprofile-social"></i>
                        <i className="fab fa-instagram styl-userprofile-social"></i>
                        <i className="fab fa-behance styl-userprofile-social"></i>
                    </div>
                    <div className="col-md-4">
                        <div className="align-profile-eyediv">
                            <i className="fas fa-eye styl-eye-icon"></i>
                            <span className="eye-text">Viewed</span>
                            <br />
                            <span className="align-eye-content">150</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">

                <div class="wrapper">

                    <div class="tabs-panel">

                        <input type="radio" id="test1" name="tab" checked="checked" />
                        <input type="radio" id="test2" name="tab" />

                        <div class="tabs styl-profile-tabs">
                            <div class="tab">
                                <label for="test1" onclick>
                                    <div class="content">
                                        <div class="text styl-tb-text">Co-Founders</div>
                                    </div>
                                </label>
                            </div>
                            <div class="tab">
                                <label for="test2" onclick>
                                    <div class="content">
                                        <div class="text styl-tb-text">Employees</div>
                                    </div>
                                </label>
                            </div>

                        </div>

                        <div class="separator"></div>

                        <div class="panels">

                            <div class="tab-panel" id="panel1">
                                <div class="content align-contet-profile">

                                    <div class="list-group-item d-flex styl-profile-content-section  align-items-center">
                                        <div class="m-r-20">
                                            <div class="avatar avatar-sm "><img class="avatar-img rounded-circle" src="assets/img/users/user-1.jpg" alt="users" /></div>
                                        </div>
                                        <div class="row">
                                            <div className="col-md-8">
                                                <span>Eric Chen</span>
                                                <br />
                                                <span>Founder</span>
                                            </div>

                                            <div className="col-md-4">
                                            <Link to="/cofounder" className="btn styl-view-profile-btn">View Profile</Link>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="list-group-item d-flex styl-profile-content-section  align-items-center">
                                        <div class="m-r-20">
                                            <div class="avatar avatar-sm "><img class="avatar-img rounded-circle" src="assets/img/users/user-1.jpg" alt="users" /></div>
                                        </div>
                                        <div class="row">
                                            <div className="col-md-8">
                                                <span>Eric Chen</span>
                                                <br />
                                                <span>Founder</span>
                                            </div>

                                            <div className="col-md-4">
                                            <Link to="/cofounder" className="btn styl-view-profile-btn">View Profile</Link>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="list-group-item d-flex styl-profile-content-section  align-items-center">
                                        <div class="m-r-20">
                                            <div class="avatar avatar-sm "><img class="avatar-img rounded-circle" src="assets/img/users/user-1.jpg" alt="users" /></div>
                                        </div>
                                        <div class="row">
                                            <div className="col-md-8">
                                                <span>Eric Chen</span>
                                                <br />
                                                <span>Founder</span>
                                            </div>

                                            <div className="col-md-4">
                                            <Link to="/cofounder" className="btn styl-view-profile-btn">View Profile</Link>
                                            </div>

                                        </div>

                                    </div>

                                    

                                    <div class="list-group-item d-flex styl-profile-content-section  align-items-center">
                                        <div class="m-r-20">
                                            <div class="avatar avatar-sm "><img class="avatar-img rounded-circle" src="assets/img/users/user-1.jpg" alt="users" /></div>
                                        </div>
                                        <div class="row">
                                            <div className="col-md-8">
                                                <span>Eric Chen</span>
                                                <br />
                                                <span>Founder</span>
                                            </div>

                                            <div className="col-md-4">
                                            <Link to="/cofounder" className="btn styl-view-profile-btn">View Profile</Link>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                            <div class="tab-panel" id="panel2">
                                <div class="content align-contet-profile">

                                    <div class="list-group-item d-flex styl-profile-content-section  align-items-center">
                                        <div class="m-r-20">
                                            <div class="avatar avatar-sm "><img class="avatar-img rounded-circle" src="assets/img/users/user-1.jpg" alt="users" /></div>
                                        </div>
                                        <div class="row">
                                            <div className="col-md-8">
                                                <span>Eric Chen</span>
                                                <br />
                                                <span>Founder</span>
                                            </div>

                                            <div className="col-md-4">
                                            <Link to="/employee" className="btn styl-view-profile-btn">View Profile</Link>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="list-group-item d-flex styl-profile-content-section  align-items-center">
                                        <div class="m-r-20">
                                            <div class="avatar avatar-sm "><img class="avatar-img rounded-circle" src="assets/img/users/user-1.jpg" alt="users" /></div>
                                        </div>
                                        <div class="row">
                                            <div className="col-md-8">
                                                <span>Eric Chen</span>
                                                <br />
                                                <span>Founder</span>
                                            </div>

                                            <div className="col-md-4">
                                            <Link to="/employee" className="btn styl-view-profile-btn">View Profile</Link>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="list-group-item d-flex styl-profile-content-section  align-items-center">
                                        <div class="m-r-20">
                                            <div class="avatar avatar-sm "><img class="avatar-img rounded-circle" src="assets/img/users/user-1.jpg" alt="users" /></div>
                                        </div>
                                        <div class="row">
                                            <div className="col-md-8">
                                                <span>Eric Chen</span>
                                                <br />
                                                <span>Founder</span>
                                            </div>

                                            <div className="col-md-4">
                                            <Link to="/employee" className="btn styl-view-profile-btn">View Profile</Link>
                                            </div>

                                        </div>

                                    </div>


                                    <div class="list-group-item d-flex styl-profile-content-section  align-items-center">
                                        <div class="m-r-20">
                                            <div class="avatar avatar-sm "><img class="avatar-img rounded-circle" src="assets/img/users/user-1.jpg" alt="users" /></div>
                                        </div>
                                        <div class="row">
                                            <div className="col-md-8">
                                                <span>Eric Chen</span>
                                                <br />
                                                <span>Founder</span>
                                            </div>

                                            <div className="col-md-4">
                                            <Link to="/employee" className="btn styl-view-profile-btn">View Profile</Link>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

           

            
        </div>

        
    </div>
</div>
        );
    }
}

export default Profile;