import React from "react"
import Header from "../../header/header"
import Modal from "../../header/modal"
import Idea from "./idea"
class index extends React.Component{
    render(){
        return(
            <React.Fragment>
             <Header />
             <Modal />
             <Idea />
            </React.Fragment>
        )
    }
}
export default(index)