import React from "react"
class CreatePost extends React.Component {
    
  
    render() {
        
        return (
            <div className="styl-userProfile-Section">
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-md-12">
                      <div className="styl-create-post-btn">
                      <button type="button" className=" styl-create-btn btn btn-primary" data-toggle="modal" data-target="#slideRightModal">
                                    CREATE POST
                    </button> 
                      </div>


                                <div className="modal fade modal-slide-right" id="slideRightModal" tabindex="-1" role="dialog"
                                     aria-labelledby="slideRightModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="slideRightModalLabel">Create Post</h5>
                                                <button type="button" className="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                            <div class="card m-b-30 box-shawdow-card-step">
                            <div class="card-body ">
                                <form class="" action="#">
                                    <div class="form-row">
                                        <div class="form-group floating-label col-md-6">
                                            <label>Email</label>
                                            <input type="email" class="form-control" placeholder="Email" />
                                        </div>
                                        <div class="form-group floating-label col-md-6">
                                            <label>Password</label>
                                            <input type="password" class="form-control " />
                                        </div>
                                    </div>
                                    <div class="form-group floating-label">
                                        <label>Address</label>
                                        <input type="text" class="form-control" placeholder="Address 1234 Main St" />
                                    </div>
                                    <div class="form-group floating-label">
                                        <label>Address 2</label>
                                        <input type="text" class="form-control" placeholder="Address 2 Apartment, studio, or floor" />
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group floating-label col-md-6">
                                            <label>City</label>
                                            <input type="text" class="form-control" placeholder="City" />
                                        </div>
                                        <div class="form-group floating-label show-label col-md-4">
                                            <label>State</label>
                                            <select class="form-control">
                                                <option selected>Choose...</option>
                                                <option>Remember</option>
                                                <option>keeping</option>
                                                <option>things</option>
                                                <option>Classy</option>
                                                <option>is important</option>
                                            </select>
                                        </div>
                                        <div class="form-group floating-label col-md-2">
                                            <label>Zip</label>
                                            <input type="text" class="form-control" placeholder="Zip" />
                                        </div>
                                    </div>

                                    <button type="submit" class="btn btn-success styl-btn-step">Create Account</button>

                                </form>
                            </div>
                        </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                                    Close
                                                </button>
                                                <button type="button" className="btn btn-primary">Save changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                     </div>

                  </div>

               </div>
           
                             
           </div>
      
); }
}

export default CreatePost;