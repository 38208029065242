import React from "react"
import Header from '../header/header'
import Modal from '../header/modal'
import MainSection from './main-section'
import MainCards from './main-cards'
import IdeaCards from './ideacards'
import Footer from '../footer/footer'
class index extends React.Component{
    render() {
        return (
          <React.Fragment>
           <Header />
           <Modal />
           <MainSection />
           <MainCards />
           <IdeaCards />
           <Footer />
          </React.Fragment>
        );
      }
}
export default(index)