import React from "react"
import Header from "../header/header"
import Modal from "../header/modal"
import Crud from "./crud.js"
class index extends React.Component{
    render(){
        return(
            <React.Fragment>
             <Header />
             <Modal />
             <Crud />
            </React.Fragment>
        )
    }
}
export default(index)