import React, { Component } from 'react'

export class MakerSuccess extends Component {
    render() {
        return (
            <div className="styl-maker-success-heading">
                <h1 className="styl-maker-success-h1">Information Added Successfully!</h1>
            </div>
        )
    }
}

export default MakerSuccess