import React from "react"
import {Link } from 'react-router-dom'
class profile extends React.Component{
    render(){
        return(
          <div className="container-fluid styl-pro-container">
              <div className="row">

                  <div className="col-md-2">

                  <div className="card styl-profile-tag styl-sidebar-cards">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/profile" className="sidebar-tags"><i className="far fa-id-card styl-sidebar-icon"></i> PROFILE</Link>
                    </div>
                    </div>

                   <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/idea" className="sidebar-tags"><i className="fas fa-lightbulb styl-sidebar-icon"></i>  IDEA</Link>
                    </div>
                    </div>

                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/company" className="sidebar-tags"><i className="fas fa-building styl-sidebar-icon"></i>  COMPANY</Link>
                    </div>
                    </div>

                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/team" className="sidebar-tags"><i className="fas fa-users  styl-sidebar-icon"></i>  TEAM</Link>
                    </div>
                    </div>

                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/model" className="sidebar-tags"><i className="fas fa-chart-bar styl-sidebar-icon"></i> MODEL</Link>
                    </div>
                    </div>

                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/market" className="sidebar-tags"><i className="fas fa-poll styl-sidebar-icon"></i>  MARKET</Link>
                    </div>
                    </div>

                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/timing" className="sidebar-tags"><i className="far fa-hourglass styl-sidebar-icon"></i>  TIMING</Link>
                    </div>
                    </div>

                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/deck" className="sidebar-tags"><i className="fas fa-clipboard-check styl-sidebar-icon"></i>  DECK</Link>
                    </div>
                    </div>

                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/share" className="sidebar-tags"><i className="fas fa-share-alt styl-sidebar-icon"></i>  SHARE</Link>
                    </div>
                    </div>

                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/video" className="sidebar-tags"><i className="fab fa-youtube styl-sidebar-icon"></i>  VIDEO</Link>
                    </div>
                    </div>

                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/fund" className="sidebar-tags"><i className="fas fa-funnel-dollar styl-sidebar-icon"></i>  FUNDRAISING</Link>
                    </div>
                    </div>

                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/track" className="sidebar-tags"><i className="fas fa-chalkboard-teacher styl-sidebar-icon"></i>  TRACTION</Link>
                    </div>
                    </div>

                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                    <div className="card-body styl-sidebar-cdbdy">
                    <Link to="/campaign" className="sidebar-tags"><i className="fas fa-bullhorn styl-sidebar-icon"></i>  CAMPAIGN</Link>
                    </div>
                    </div>

                  </div>



                  <div className="col-md-10">
                    <div className="form-row">
                      <div className="container styl-form-container">
                          <div className="styl-heading">
                            <h1>PROFILE INFORMATION</h1>
                            <hr />
                          </div>
                      <div className="col-12 form-group">
                          <label className="lbl-styl">Maker Name</label>
                         <input className="form-control form-control-lg styl-input" type="text" />
                      </div>

                      <div className="col-12 form-group">
                          <label className="lbl-styl">Maker Designation</label>
                         <input className="form-control form-control-lg styl-input" type="text" />
                      </div>

                      <div className="col-12 form-group ">
                         <label className="font-secondary lbl-styl">Interest</label>
                            <select className="form-control js-select2 styl-select">
                                <option>Option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                            </select>
                      </div>

                      <div className="form-group col-12">
                         <label className="font-secondary lbl-styl">Help Others</label>
                            <select className="form-control js-select2 styl-select">
                                <option>Option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                            </select>
                      </div>

                      <div className="form-group col-12">
                         <label className="font-secondary lbl-styl">Need Help</label>
                            <select className="form-control js-select2 styl-select">
                            <option>Option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                            </select>
                      </div>

                      <div className="form-group col-12">
                         <label className="font-secondary lbl-styl">Location</label>
                            <select className="form-control js-select2 styl-select">
                            <option>Option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                            </select>
                      </div>

                      
                      <div className="form-group col-12">
                         <label className="font-secondary lbl-styl">City</label>
                            <select className="form-control js-select2 styl-select">
                                <option>Option 1</option>
                                <option>option 2</option>
                                <option>option 3</option>
                            </select>
                      </div>

                      <div className="col-12 form-group">
                          <label className="lbl-styl">Add LinkedIn URL</label>
                         <input className="form-control form-control-lg styl-input" type="text" />
                      </div>

                      <div className="align-btn">
                       <button className="styl-btn1">CANCEL</button>
                       <button className="styl-btn2">SAVE & CONTINUE</button>
                      </div>

                      </div>
                     


                    </div>

                    </div>



              </div>

          </div>
        );
    }
}
export default (profile)