import React from "react"
import Header from '../header/header'
import Modal from '../header/modal'
import SignUp from './signup'
class index extends React.Component{
    render() {
        return (
          <React.Fragment>
           <Header />
           <Modal />
           <SignUp />
          </React.Fragment>
        );
      }
}
export default(index)