import React from "react"
import Header from '../header/header'
import Modal from '../header/modal'
import FounderProfile from './founder-Profile'
import Infomration from './founder-information'
class index extends React.Component {
   
    render() {
        return (
            <React.Fragment>
              <Header />
              <Modal />
              <FounderProfile />
              <Infomration />
            </React.Fragment>
        );
    }
}

export default index;