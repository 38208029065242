import React from "react"

class founderinformation extends React.Component {
   
    render() {
        return (
           <div className="container-fluid">
   <div className="row styl-row-bussiness2">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">SKILLS</h1> 
                              <hr className="styl-hr-bussiness" />
                              <div className="align-btn-skills2">
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Interface</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">Product Management</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Experience</button>                      
                            </div>
                            
                           <div className="row styl-row-progress2">
                            <div className="col-md-2">
                              <span className="styl-skill-span">Skill Level 60%</span>
                            <div class="progress">
                          <div class="progress-bar progress-bar-striped active" role="progressbar"
                          aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"60%"}}>
                            60%
                          </div>
                        </div>

                            </div>

                            <div className="col-md-2">
                            <span className="styl-skill-span">Skill Level 70%</span>
                            <div class="progress">
                          <div class="progress-bar progress-bar-striped active" role="progressbar"
                          aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"70%"}}>
                            70%
                          </div>
                        </div>
                            </div>
                            <div className="col-md-2">
                            <span className="styl-skill-span">Skill Level 80%</span>
   <div class="progress">
                          <div class="progress-bar progress-bar-striped active" role="progressbar"
                          aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"80%"}}>
                            80%
                          </div>
                        </div>
                            </div>
                           </div>


                   </div>


                </div>

                <div className="row styl-row-bussiness2">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">STARTUP EXPERIENCE</h1> 
                              <hr className="styl-hr-bussiness" />
                             <h1 className="styl-product-h1">PREZI VIDEO <span className="styl-product-span">(2015-2016)</span></h1>
                             <div className="styl-product-sec">
                              <h1 className="styl-product-sec2"> PRODUCT DESIGNER</h1>
                             </div>

                             <p className="styl-content-product">
                             You and your content, together in real time<br />
                            Prezi Video is the best way to stay connected with your audience and keep them engaged.<br /> No video editing experience required.
                            Easy to use, hard to forget.<br />
                            Create impressive visuals in minutes, then jump into a video conference, or record<br /> videos to share or embed with just a few clicks.
                            More effective video conferences.<br />
                          

                             </p>
                   </div>
                </div>


                <div className="row styl-row-bussiness2">
                <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">LOOKING FOR START'UPS</h1> 
                              <hr className="styl-hr-bussiness" />
                              <div className="align-btn-skill-emp">
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Interface</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">Product Management</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Experience</button> 
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">Product Management</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Interface</button>
                            </div>
                   </div>
                </div>


                <div className="row styl-row-bussiness2">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">INDUSTRY INTEREST</h1> 
                              <hr className="styl-hr-bussiness" />
                              <div className="align-btn-skill-emp">
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Interface</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">Product Management</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Experience</button> 
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">Product Management</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Interface</button>
                            </div>
                   </div>
                </div>


                <div className="row styl-row-bussiness2">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">EXPERIENCE</h1> 
                              <hr className="styl-hr-bussiness" />
                             <h1 className="styl-product-h1">UBISOFT <span className="styl-product-span">(Feburary,2015-March,2016)</span></h1>
                             <div className="styl-product-sec">
                              <h1 className="styl-product-sec2"> PRODUCT DESIGNER</h1>
                             </div>

                             <p className="styl-content-product">
                             You and your content, together in real time<br />
                            Prezi Video is the best way to stay connected with your audience and keep them engaged.<br /> No video editing experience required.
                            Easy to use, hard to forget.<br />
                            Create impressive visuals in minutes, then jump into a video conference, or record<br /> videos to share or embed with just a few clicks.
                            More effective video conferences.<br />
                             </p>
                             <hr/>
                             <h1 className="styl-product-h1">Google <span className="styl-product-span">(Feburary,2015-March,2016)</span></h1>
                             <div className="styl-product-sec">
                              <h1 className="styl-product-sec2"> PRODUCT DESIGNER</h1>
                             </div>

                             <p className="styl-content-product">
                             You and your content, together in real time<br />
                            Prezi Video is the best way to stay connected with your audience and keep them engaged.<br /> No video editing experience required.
                            Easy to use, hard to forget.<br />
                            Create impressive visuals in minutes, then jump into a video conference, or record<br /> videos to share or embed with just a few clicks.
                            More effective video conferences.<br />
                             </p>
                   </div>
                </div>
          

                <div className="row styl-row-bussiness2">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">EDUCATION</h1> 
                              <hr className="styl-hr-bussiness" />
                             <h1 className="styl-product-h1">HAVARADS UNIVERSITY <span className="styl-product-span">(2009-2011)</span></h1>
                             <div className="styl-product-sec">
                              <h1 className="styl-product-sec2"> ph.D Data Sciences</h1>
                             </div>

                             <h1 className="styl-product-h1 mrgn-education-h1">YALE UNIVERSITY <span className="styl-product-span">(2005-2009)</span></h1>
                             <div className="styl-emp-secc">
                              <h1 className="styl-product-sec2"> BSCS</h1>
                             </div>
                   </div>
                </div>

                <div className="row styl-row-bussiness2">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">CERTIFICATION</h1> 
                              <hr className="styl-hr-bussiness" />
                             <h1 className="styl-product-h1">LORAIN COUNTRY COMMUNITY COLLEGE<span className="styl-product-span">(8 Months,2014)</span></h1>
                             <div className="styl-product-secc3">
                              <h1 className="styl-product-sec2">Application Integeration</h1>
                             </div>

                             <h1 className="styl-product-h1 mrgn-education-h1">BERKLEY GLOBAL <span className="styl-product-span">(2015-2014)</span></h1>
                             <div className="styl-product-secc3 mrgn-botm-emp-edu">
                              <h1 className="styl-product-sec2">Application Integeration</h1>
                             </div>
                   </div>
                </div>



                
           </div> 
        );
    }
}

export default founderinformation;