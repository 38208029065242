import React from "react"

class Modal extends React.Component{
    render() {
        return(
            <div className="modal modal-slide-left  fade" id="siteSearchModal" tabindex="-1" role="dialog" aria-labelledby="siteSearchModal" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
            <div className="modal-body p-all-0" id="site-search">
                <button type="button" className="close light" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div className="form-dark bg-dark text-white p-t-60 p-b-20 bg-dots">
                    <h3 className="text-uppercase    text-center  fw-300 "> Search</h3>
                    <div className="container-fluid">
                        <div className="col-md-10 p-t-10 m-auto">
                            <input type="search" placeholder="Search Something" className=" search form-control form-control-lg" />
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="bg-dark text-muted container-fluid p-b-10 text-center text-overline">
                        results
                    </div>
                    <div className="list-group list  ">
                        <div className="list-group-item d-flex  align-items-center">
                            <div className="m-r-20">
                                <div className="avatar avatar-sm ">
                                    <img className="avatar-img rounded-circle" src="assets/img/users/user-3.jpg" alt="user-image" />
                                </div>
                            </div>
                            <div className="">
                                <div className="name">Eric Chen</div>
                                <div className="text-muted">Developer</div>
                            </div>
                        </div>
                        <div className="list-group-item d-flex  align-items-center">
                            <div className="m-r-20">
                                <div className="avatar avatar-sm ">
                                    <img className="avatar-img rounded-circle" src="assets/img/users/user-4.jpg" alt="user-image" />
                                </div>
                            </div>
                            <div className="">
                                <div className="name">Sean Valdez</div>
                                <div className="text-muted">Marketing</div>
                            </div>
                        </div>
                        <div className="list-group-item d-flex  align-items-center">
                            <div className="m-r-20">
                                <div className="avatar avatar-sm ">
                                    <img className="avatar-img rounded-circle" src="assets/img/users/user-8.jpg" alt="user-image" />
                                </div>
                            </div>
                            <div className="">
                                <div className="name">Marie Arnold</div>
                                <div className="text-muted">Developer</div>
                            </div>
                        </div>
                        <div className="list-group-item d-flex  align-items-center">
                            <div className="m-r-20">
                                <div className="avatar avatar-sm ">
                                    <div className="avatar-title bg-dark rounded">
                                        <i className="mdi mdi-24px mdi-file-pdf"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="name">SRS Document</div>
                                <div className="text-muted">25.5 Mb</div>
                            </div>
                        </div>
                        <div className="list-group-item d-flex  align-items-center">
                            <div className="m-r-20">
                                <div className="avatar avatar-sm ">
                                    <div className="avatar-title bg-dark rounded">
                                        <i className="mdi mdi-24px mdi-file-document-box"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="name">Design Guide.pdf</div>
                                <div className="text-muted">9 Mb</div>
                            </div>
                        </div>
                        <div className="list-group-item d-flex  align-items-center">
                            <div className="m-r-20">
                                <div className="avatar avatar-sm ">
                                    <div className="avatar avatar-sm  ">
                                        <div className="avatar-title bg-primary rounded">
                                            <i className="mdi mdi-24px mdi-code-braces"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="name">response.json</div>
                                <div className="text-muted">15 Kb</div>
                            </div>
                        </div>
                        <div className="list-group-item d-flex  align-items-center">
                            <div className="m-r-20">
                                <div className="avatar avatar-sm ">
                                    <div className="avatar avatar-sm ">
                                        <div className="avatar-title bg-info rounded">
                                            <i className="mdi mdi-24px mdi-file-excel"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="name">June Accounts.xls</div>
                                <div className="text-muted">6 Mb</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
        )
    }
}
export default (Modal)