import React, { Component } from 'react'

export class MakerModal extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };

    render() {
        const { values, inputChange } = this.props;

        return (
            <div className="styl-userProfile-Section">
                 <div className="container-fluid">
             <div className="row">
                 <div className="col-md-12">
                  <div className="styl-step-form">
                  <div className="form-container step-form-container">
                  <div className="container">
                     <div className="row">
                         <div className="col-md-2">
                         <i className="fas fa-user-circle user-icon-maker"></i>
                         </div>
                         <div className="col-md-10">
                         <div className="upload-btn-wrapper">
                            <button className="btn upload-btn-maker">
                            <i className="fas fa-camera cam-mrgn"></i>
                             ADD PICTURE
                             </button>
                            <input type="file" name="myfile" />
                            </div> 
                            <p className="styl-maker-p">Image resolution:Min 500 x 500 px <br /> & Max 4096 x 4096 px</p>                        
                            </div>
                      </div>
                     </div>
                <div className="form-group">
                    <label htmlFor="name" className="lbl-color-maker">Maker Name</label>
                    <input type="text" className="form-control styl-maker-inputs" name="name" onChange={inputChange('name')} value={values.name} />
                </div>
                <div className="form-group">
                    <label htmlFor="email" className="lbl-color-maker">Maker Role</label>
                    <select className="form-control styl-maker-inputs" name="role" onChange={inputChange('role')} value={values.role}>
                        <option>a</option>
                        <option>b</option>
                        <option>c</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="phone" className="lbl-color-maker">Your Location</label>
                    <select className="form-control styl-maker-inputs" name="location" onChange={inputChange('location')} value={values.location}>
                        <option>a</option>
                        <option>b</option>
                        <option>c</option>
                    </select>                </div>
                <div className="form-group">
                    <label htmlFor="password" className="lbl-color-maker">City</label>
                    <select className="form-control styl-maker-inputs" name="city" onChange={inputChange('city')} value={values.city}>
                        <option>a</option>
                        <option>b</option>
                        <option>c</option>
                    </select>                </div>
                <div className="form-group">
                    <label htmlFor="password" className="lbl-color-maker">What topics you are interested in?</label>
                    <select className="form-control styl-maker-inputs" name="topics" onChange={inputChange('topics')} value={values.topics}>
                        <option>a</option>
                        <option>b</option>
                        <option>c</option>
                    </select>                </div>

                <br />

                <div className="text-right">
                    <button className="btn btn-primary styl-continue-btn" onClick={this.continue}>Continue</button>
                </div>
            </div>
                  </div>
                 </div>
             </div>
         </div>
</div>
        
        )
    }
}

export default MakerModal
