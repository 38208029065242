import React from "react"
import Header from "../header/header"
import Modal from "../header/modal"
import Investorprofile from "./investorProfile"
import Investorinformation from "./InvestorInformation"
class index extends React.Component {

    render() {
        return (
            <React.Fragment>
            <Header />
            <Modal />
            <Investorprofile />
            <Investorinformation />
            
            </React.Fragment>
        );
    }
}

export default index;