import React from "react"
import Header from '../header/header'
import Modal from '../header/modal'
import GetStared from '../GetStarted/getstarted'
import GetService from './getServices'
class index extends React.Component{
    render() {
        return (
          <React.Fragment>
           <Header />
           <Modal />
           <GetStared />
           <GetService />
          </React.Fragment>
        );
      }
}
export default(index)