import React from 'react';
import './App.css';
import Index from './components/home/index'
import GetStarted from './components/GetStarted/index'
import SignUp from './components/Signup/index'
import Profile from './components/Forms/Profile/index'
import Idea from './components/Forms/Idea/index'
import Company from './components/Forms/company/index'
import Team from './components/Forms/Team/index'
import BussinessModel from './components/Forms/BussinessModel/index'
import Market from './components/Forms/Market/index'
import Timing from './components/Forms/Timing/index'
import Deck from './components/Forms/Deck/index'
import Share from './components/Forms/Share/index'
import Video from './components/Forms/Video/index'
import FundRaising from './components/Forms/Fundraising/index'
import Campaign from './components/Forms/Campaign/index'
import Crud from './components/crud/index'
import ProfileSection from './components/ProfileSection/index'
import Cofounder from './components/Co-Founder/index'
import Student from './components/Students/index'
import Employee from './components/Employee/index'
import Investor from './components/Investor/index'
import Maker from "./components/Maker/index"
import {BrowserRouter,Route} from 'react-router-dom'
function App() {
  return (
    <BrowserRouter>
    <div>
      <Route path="/" exact component={Index} />
      <Route path="/getStarted" component={GetStarted} />
      <Route path="/signup" component={SignUp} />
      <Route path="/profile" component={Profile} />
      <Route path="/idea" component={Idea} />
      <Route path="/company" component={Company} />
      <Route path="/team" component={Team} />
      <Route path="/model" component={BussinessModel} />
      <Route path="/market" component={Market} />
      <Route path="/timing" component={Timing} />
      <Route path="/deck" component={Deck} />
      <Route path="/share" component={Share} />
      <Route path="/video" component={Video} />
      <Route path="/fund" component={FundRaising} />
      <Route path="/campaign" component={Campaign} />
      <Route path="/crud" component={Crud} />
      <Route path="/userprofile" component={ProfileSection} />
      <Route path="/cofounder" component={Cofounder} />
      <Route path="/student" component={Student} />
      <Route path="/employee" component={Employee} />
      <Route path="/Investor" component={Investor} />
      <Route path="/Maker" component={Maker} />
    </div>
    </BrowserRouter>
  );
}

export default App;
