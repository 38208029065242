import React from "react"
import Header from "../header/header"
import Modal from "../header/modal"
import Makerform from "./MakerForm"
class index extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Header />
                <Modal />
                <Makerform />
            </React.Fragment>
        );
    }
}

export default index;