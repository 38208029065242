import React from "react"
import {Link } from 'react-router-dom'
class MainSection extends React.Component{
    render() 
    {
        return(
            <div className="container-fluid bg-image">
            <div className="row">
               <div className="align-content">
                   <h1 className="styl-main-heading">FIND THE <br /> INNOVATION THAT <br /> MATTERS TO YOU</h1>
                   <p className="styl-main-content">SMARTER INNOVATION ALERTS FOR SAVVY <br /> EXECUTIVES MARKETERS AND SALES PROFESSIONALS</p>
  
                   <div className="align-main-btn">
                      <span>
                      <Link to="/getstarted" className="btn styl-main-btn1">GET STARTED</Link>
                      <Link to="/crud" className="btn styl-main-btn2">SIGN IN</Link>
                      </span> <br />
                      <button className="styl-main-btn3" >FIND INNOV'EM</button>
                  </div>
               </div>
  
              
            </div>
        </div>




        )
    }
}
export default (MainSection)