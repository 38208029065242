import React from "react"

class signup extends React.Component{
    render() {
        return(
            <div className="container-fluid signupbg-img">
               <div className="row">
                 <div className="col-md-12">
                     
                   <div className="container">
                      <div className="row">
                         <div className="col-md-12">
                            <div className="styl-sign-btnContainer">
                                <button className="styl-signup-btn1">SIGN UP</button>
                                <button className="styl-signup-btn2">SIGN IN</button>
                            </div>
                         </div>
                      </div>
                   </div>

                   <div className="container">
                      <div className="row">
                         <div className="col-md-12">
                            <div className="styl-sign-btnContainer2">
                            <div className="col-12 form-group">
                                <label className="styl-lbl-signup">Username or Email</label>
                                <input className="form-control form-control-lg styl-input-signpup" type="text"  />
                            </div>

                            <div className="col-12 form-group">
                                <label className="styl-lbl-signup2">Password</label>
                                <input className="form-control form-control-lg styl-input-signpup" type="password"  />
                            </div>

                            <div className="col-12 form-group mrgn-remember-box">
                               <input type="checkbox" /> <span className="styl-span-signup">Remember Me</span>
                            </div>

                            <div className="styl-signup-signin">
                                <button className="styl-sign-in-btn">SIGN IN</button>
                            </div>


                            </div>
                         </div>
                      </div>
                   </div>

                   <div className="container">
                      <div className="row">
                         <div className="col-md-12">
                            <div className="styl-sign-forgotContainer">
                               <a className="styl-forgt-password">FORGET PASSWORD</a>
                            </div>
                         </div>
                      </div>
                   </div>


                </div>
               </div>
            </div>
        );
    }
}
export default(signup)