import React from "react"
import Header from "../header/header"
import Modal from "../header/modal"
import StudentProfile from './studentProfile'
import StudentInformation from './studentInformation'
class index extends React.Component{
    render(){
        return(
          <React.Fragment>
              <Header />
              <Modal />
              <StudentProfile />
              <StudentInformation/>
              
          </React.Fragment>
        )
    }
}
export default(index)