import React from "react";
import Header from "../header/header"
import Modal from "../header/modal"
import Empprofile from "./EmpProfile"
import Empinformation from "./EmpInformation"
class index extends React.Component {

    render() {
        return (
           <React.Fragment>
            <Header />
            <Modal />
            <Empprofile />
            <Empinformation />
           </React.Fragment>
        );
    }
}

export default index;