import React, { Component } from 'react'

export class MakerConfirm extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const {
            values: { name, role, location, city,topics, companyname, founded, industry,code }
        } = this.props;

        return (
           <div className="container-fluid">
               <div className="row">
                   <div className="col-md-12">
                   <div className="form-container step-form-container">
                <h1 className="styl-confirm-heading">CONFIRM YOUR INFORMATION</h1>
                <ul class="list-group">
                    <li class="list-group-item">Name: {name}</li>
                    <li class="list-group-item">Role: {role}</li>
                    <li class="list-group-item">Location: {location}</li>
                    <li class="list-group-item">City: {city}</li>
                    <li class="list-group-item">Topics: {topics}</li>
                    <li class="list-group-item">Company Name: {companyname}</li>
                    <li class="list-group-item">Founded: {founded}</li>
                    <li class="list-group-item">Industry: {industry}</li>
                    <li class="list-group-item">Code: {code}</li>
                   
                </ul>

                <br /><br />

                <div className="row">
                    <div className="col-6">
                        <button className="btn  styl-continue-btn" onClick={this.back}>Back</button>
                    </div>
                    <div className="col-6 text-right">
                        <button className="btn  styl-continue-btn" onClick={this.continue}>Continue</button>
                    </div>
                </div>
            </div>
                   </div>
               </div>
           </div>
        )
    }
}

export default MakerConfirm
