import React, { Component } from "react"
import {Link } from 'react-router-dom'
class Crud extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
            classHere: false
        }
    }
    
    toggleClass = () => {
        const currentState = this.state.classHere;
        console.log(currentState);
        this.setState({
            classHere: !currentState
        });
    };

render() {
    return(
       <div className="styl-signup-section">
          <div className={this.state.classHere ? 'container styl-main-signup-container right-panel-active' : 'container styl-main-signup-container'} id="container">
            <div class="form-container sign-up-container">
                <form action="#">
                    <h1 className="styl-signup-h1">Create Account</h1>
                    <div className="social-container">
                        <a href="#" className="social styl-sinup-anchor"><i class="fab fa-facebook-f"></i></a>
                        <a href="#" className="social styl-sinup-anchor"><i class="fab fa-google-plus-g"></i></a>
                        <a href="#" className="social styl-sinup-anchor"><i class="fab fa-linkedin-in"></i></a>
                    </div>
                    <span className="styl-signup-span">or use your email for registration</span>
                    <input type="text" placeholder="Name" />
                    <input type="email" placeholder="Email" />
                    <input type="password" placeholder="Password" />
                    <button className="styl-signup-button" onClick={this.signUp}>Sign Up</button>
                </form>
            </div>
            <div class="form-container sign-in-container">
                <form action="#">
                    <h1 className="styl-signup-h1">Sign in</h1>
                    <div class="social-container">
                        <a href="#" className="social styl-sinup-anchor"><i className="fab fa-facebook-f"></i></a>
                        <a href="#" className="social styl-sinup-anchor"><i className="fab fa-google-plus-g"></i></a>
                        <a href="#" className="social styl-sinup-anchor"><i className="fab fa-linkedin-in"></i></a>
                    </div>
                    <span className="styl-signup-span">or use your account</span>
                    <input type="email" placeholder="Email" />
                    <input type="password" placeholder="Password" />
                    <a href="#" className="styl-sinup-anchor">Forgot your password?</a>
                    <Link to="/maker" className="btn ghost styl-signup-button" >Sign In</Link>
                </form>
            </div>
            <div className="overlay-container">
                <div className="overlay">
                    <div className="overlay-panel overlay-left">
                        <h1>Welcome Back!</h1>
                        <p className="styl-signup-p">To keep connected with us please login with your personal info</p>
                        <button className="ghost styl-signup-button" onClick={this.toggleClass} id="signIn">Sign In</button>
                    </div>
                    <div className="overlay-panel overlay-right">
                        <h1>Hello, Friend!</h1>
                        <p className="styl-signup-p">Enter your personal details and start journey with us</p>
                        <button className="ghost styl-signup-button" onClick={this.toggleClass} id="signUp">Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
       </div>
);
}
}
export default(Crud)