import React from "react"
import {Link } from 'react-router-dom'
class header extends React.Component{
    render() 
    {
        return(
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="nav-link" data-target="#siteSearchModal" data-toggle="modal" href="#">
                <span className="styl-span">INNOV'EM |<i className=" mdi mdi-magnify mdi-24px align-middle"></i> </span>
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className="align-li">
                    <ul className="navbar-nav mr-auto align-header-item">
                        <li className="nav-item dropdown alignli">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Network
                                  </a>
                            <div className="dropdown-menu align-dropdown" aria-labelledby="navbarDropdown">
                                <div className="row styl-row-1-mega-menu">
                                    <div className="col-md-3">
                                        <a className="dropdown-item" href="#">Sector</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#"> Advertising& <br />Marketing </a>
                                        <a className="dropdown-item" href="#">Computer& <br />Tech</a>
                                        <a className="dropdown-item" href="#">Archietecture& <br />Tech</a>
                                        <a className="dropdown-item" href="#">Healthcare& <br />Wellbeing</a>
                                        <a className="dropdown-item" href="#"> Government& <br />Defence</a>
                                        <a className="dropdown-item" href="#"> Agriculture& <br />Energy</a>
                                    </div>
                                    <div className="col-md-3">
                                        <a className="dropdown-item" href="#">Technology</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#"> 3D Printing</a>
                                        <a className="dropdown-item" href="#">Artifical Intelligence</a>
                                        <a className="dropdown-item" href="#">Automation</a>
                                        <a className="dropdown-item" href="#">Big Data</a>
                                        <a className="dropdown-item" href="#">Blockchain</a>
                                        <a className="dropdown-item" href="#">Geospatial</a>
                                        <a className="dropdown-item" href="#">Robotics</a>
                                        <a className="dropdown-item" href="#">Internet of Things</a>
                                        <a className="dropdown-item" href="#">Virtual Reality</a>
        
                                    </div>
                                    <div className="col-md-3">
                                        <a className="dropdown-item" href="#">Topic</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#">Circular Economy</a>
                                        <a className="dropdown-item" href="#">Diversity & Social</a>
                                        <a className="dropdown-item" href="#">Blockchain</a>
                                        <a className="dropdown-item" href="#">Geospatial</a>
                                        <a className="dropdown-item" href="#">Automation</a>
                                        <a className="dropdown-item" href="#">Big Data</a>
                                        <a className="dropdown-item" href="#"> Government& <br />Defence</a>
                                        <a className="dropdown-item" href="#"> Agriculture& <br />Energy</a>
                                    </div>
                                    <div className="col-md-3">
                                        <a className="dropdown-item" href="#">Region</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#">Artifical </a>
                                        <a className="dropdown-item" href="#">Asia </a>
                                        <a className="dropdown-item" href="#">Europe </a>
                                        <a className="dropdown-item" href="#">North America </a>
                                        <a className="dropdown-item" href="#">Oceania </a>
                                        <a className="dropdown-item" href="#">South America </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown alignli">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    DISCUSS
                                  </a>
                            <div className="dropdown-menu align-dropdown" aria-labelledby="navbarDropdown">
                                <div className="row styl-row">
                                    <div className="col-md-4">
                                        <a className="dropdown-item" href="#"> Features Articles</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#"> Innovation <br /> Snapshots </a>
                                        <a className="dropdown-item" href="#">Text Explained</a>
                                        <a className="dropdown-item" href="#">Pros & Cons</a>
                                        <a className="dropdown-item" href="#">Wise Words</a>
                                        <a className="dropdown-item" href="#"> All Features Articles</a>
                                    </div>
                                    <div className="col-md-4">
                                        <a className="dropdown-item" href="#">Reports</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#"> Redifining Retail <br /> Beyond 2020 </a>
                                        <a className="dropdown-item" href="#">CreateTech 2019-<br /> One to Watch</a>
                                        <a className="dropdown-item" href="#">2019 Innovation <br /> Study(SRI)</a>
                                    </div>
                                    <div className="col-md-4">
                                        <a className="dropdown-item" href="#">Podcasts</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#">The Future of <br /> Immersive <br /> Technology </a>
                                        <a className="dropdown-item" href="#"> Redifining Retail <br /> Beyond 2020 </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item alignli">
                            <a className="nav-link" href="#">RESOURCES</a>
                        </li>
                        <li className="nav-item">
                            <div className="dropdown">
                                <a href="#" className="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="mdi mdi-24px mdi-bell-outline"></i>
                                    <span className="notification-counter"></span>
                                </a>
                                <div className="dropdown-menu notification-container dropdown-menu-right">
                                    <div className="d-flex p-all-15 bg-white justify-content-between border-bottom ">
                                        <a href="#!" className="mdi mdi-18px mdi-settings text-muted"></a>
                                        <span className="h5 m-0">Notifications</span>
                                        <a href="#!" className="mdi mdi-18px mdi-notification-clear-all text-muted"></a>
                                    </div>
                                    <div className="notification-events bg-gray-300">
                                        <div className="text-overline m-b-5">today</div>
                                        <a href="#" className="d-block m-b-10">
                                            <div className="card">
                                                <div className="card-body">
                                                    <i className="mdi mdi-circle text-success"></i> All systems operational.
                                                </div>
                                            </div>
                                        </a>
                                        <a href="#" className="d-block m-b-10">
                                            <div className="card">
                                                <div className="card-body">
                                                    <i className="mdi mdi-upload-multiple "></i> File upload successful.
                                                </div>
                                            </div>
                                        </a>
                                        <a href="#" className="d-block m-b-10">
                                            <div className="card">
                                                <div className="card-body">
                                                    <i className="mdi mdi-cancel text-danger"></i> Your holiday has been denied
        
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
        
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-user-circle styl-profile-icon"></i>
                            </a>
                            <div className="dropdown-menu  dropdown-menu-right">
                            <Link to="/crud" className="dropdown-item"> <i class="fas fa-sign-in-alt"></i> SIGN IN</Link>
                            <Link to="/crud" className="dropdown-item"> <i class="fas fa-sign-out-alt"></i> SIGN UP</Link>
                                {/* <a className="dropdown-item" href="/crud"> <i class="fas fa-sign-in-alt"></i> SIGNIN</a> */}
                                {/* <a className="dropdown-item" href="/crud"> <i class="fas fa-sign-out-alt"></i> SIGN UP</a> */}
                          </div>
                        </li>
                    </ul>
                </div>
        
            </div>
        </nav>



        )
    }
}
export default (header)