import React from "react"
class MainCards extends React.Component{
    render() 
    {
        return(
            <div className="styl-section">
            <div className="container-fluid mrgn-container">
                <div className="mrgn-inner-cards">
                    <div className="heading-cards">
                        <h1 className="styl-home-heading">OUR SUPPORT</h1>
                        <hr />
                    </div>
                    <div className="row mrgn-row">
                        <div className="col-md-4 col-4-mrgn">
                            <img className="card-img-top styl-zindex-img3" src="./assets/img/main-images/rec1.png" alt="Card image cap" />
                            <img className="card-img-top styl-zindex-img1" src="./assets/img/main-images/path1.png" alt="Card image cap" />
                            <div className="card styl-home-cards">
                                <img className="card-img-top width-mainimg-1" src="./assets/img/main-images/Main-1.png" alt="Card image cap" />
                                <h4 className="styl-home-cardh1">SPACE ASSOCIATION</h4>
                            </div>
                        </div>
                        <div className="col-md-4 col-4-mrgn">
                            <div className="card styl-home-cards2">
                                <img className="card-img-top width-mainimg-1" src="./assets/img/main-images/Main-2.png" alt="Card image cap" />
                                <h4 className="styl-home-cardh2">FUNDRAISING</h4>
                            </div>
                        </div>
                        <div className="col-md-4 col-4-mrgn">
                            <div className="card styl-home-cards3">
                                <img className="card-img-top width-mainimg-2" src="./assets/img/main-images/Main-3.png" alt="Card image cap" />
                                <h4 className="styl-home-cardh3">CONTEST</h4>
                            </div>
                        </div>
                    </div>
        
                    <div className="row mrgn-row2">
                        <div className="col-md-4 col-4-mrgn">
                            <div className="card styl-home-cards-row2">
                                <img className="card-img-top width-mainimg-1" src="./assets/img/main-images/Main-4.png" alt="Card image cap" />
                                <h4 className="styl-home-cardh2">FACILITATE</h4>
                            </div>
                        </div>
                        <div className="col-md-4 col-4-mrgn">
                            <img className="card-img-top styl-zindex-row2-2" src="./assets/img/main-images/rec1.png" alt="Card image cap" />
                            <div className="card styl-home-cards">
                                <img className="card-img-top width-mainimg-row-2" src="./assets/img/main-images/Main-5.png" alt="Card image cap" />
                                <h4 className="styl-home-cardh1">CONTRIBUTOR</h4>
                            </div>
                        </div>
                        <div className="col-md-4 col-4-mrgn">
                            <img className="card-img-top styl-zindex-row2" src="./assets/img/main-images/rec1.png" alt="Card image cap" />
                            <img className="card-img-top styl-zindex-row2-img" src="./assets/img/main-images/path1.png" alt="Card image cap" />
                            <div className="card styl-home-cards">
                                <img className="card-img-top width-mainimg-row2" src="./assets/img/main-images/Main-6.png" alt="Card image cap" />
                                <h4 className="styl-home-cardh1">SPACE ASSOCIATION</h4>
                            </div>
                        </div>
        
                    </div>
                </div>
            </div>
        
        </div>


        )
    }
}
export default (MainCards)