import React from "react"
import {Link } from 'react-router-dom'

class idea extends React.Component{
    render(){
        return(
            <div className="container-fluid styl-pro-container">
            <div className="row">
        
                <div className="col-md-2">
        
                    <div className="card styl-profile-tag styl-sidebar-card2">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/profile" className="sidebar-tags"><i className="far fa-id-card styl-sidebar-icon"></i> PROFILE</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-cards mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/idea" className="sidebar-tags"><i className="fas fa-lightbulb styl-sidebar-icon"></i>  IDEA</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/company" className="sidebar-tags"><i className="fas fa-building styl-sidebar-icon"></i>  COMPANY</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/team" className="sidebar-tags"><i className="fas fa-users  styl-sidebar-icon"></i>  TEAM</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/model" className="sidebar-tags"><i className="fas fa-chart-bar styl-sidebar-icon"></i> MODEL</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/market" className="sidebar-tags"><i className="fas fa-poll styl-sidebar-icon"></i>  MARKET</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/timing" className="sidebar-tags"><i className="far fa-hourglass styl-sidebar-icon"></i>  TIMING</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/deck" className="sidebar-tags"><i className="fas fa-clipboard-check styl-sidebar-icon"></i>  DECK</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/share" className="sidebar-tags"><i className="fas fa-share-alt styl-sidebar-icon"></i>  SHARE</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/video" className="sidebar-tags"><i className="fab fa-youtube styl-sidebar-icon"></i>  VIDEO</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/fund" className="sidebar-tags"><i className="fas fa-funnel-dollar styl-sidebar-icon"></i>  FUNDRAISING</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/track" className="sidebar-tags"><i className="fas fa-chalkboard-teacher styl-sidebar-icon"></i>  TRACTION</Link>
                        </div>
                    </div>
        
                    <div className="card styl-sidebar-card2 mrgn-top-icon">
                        <div className="card-body styl-sidebar-cdbdy">
                        <Link to="/campaign" className="sidebar-tags"><i className="fas fa-bullhorn styl-sidebar-icon"></i>  CAMPAIGN</Link>
                        </div>
                    </div>
        
                </div>
        
                <div className="col-md-10">
                    <div className="form-row">
                        <div className="container styl-form-container">
                            <div className="styl-heading">
                                <h1>IDEA DETAILS</h1>
                                <hr />
                            </div>
        
                            <div className="styl-upload-section">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src="./assets/img/main-images/Upload.png" class="align-img" alt="" />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="align-upload-div">
                                            <h4 className="stylheading1">PRODUCT IMAGES</h4>
                                            <h5 className="stylheading2">Drag & Drop</h5>
                                            <h5 className="stylheading3">OR</h5>
                                            <div className="upload-btn-wrapper">
                                                <button className="btn btn-upload">Upload a file</button>
                                                <input type="file" name="myfile" />
                                            </div>
                                            <h4 className="stylheading4">Maximum File Size <span class="style-span-txt">75 MB</span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 form-group mrgn-input">
                                <label className="lbl-styl">Idea Name</label>
                                <input className="form-control form-control-lg styl-input" type="text" />
                            </div>
        
                            <div className="form-group col-12">
                                <label className="lbl-styl">Description</label>
                                <textarea className="form-control styl-txtarea" cols="30" rows="3"></textarea>
                            </div>
        
                            <div className="col-12 form-group ">
                                <label className="font-secondary lbl-styl">Platform</label>
                                <select className="form-control js-select2 styl-select">
                                    <option>Option 1</option>
                                    <option>option 2</option>
                                    <option>option 3</option>
                                </select>
                            </div>
        
                            <div className="col-12 form-group">
                                <label className="lbl-styl">Launching State</label>
                                <input className="form-control form-control-lg styl-input" type="text" />
                            </div>
        
                            <div className="align-btn">
                                <button className="styl-btn1">CANCEL</button>
                                <button className="styl-btn2">SAVE & CONTINUE</button>
                            </div>
        
                        </div>
        
                    </div>
        
                </div>
        
            </div>
        
        </div>
        );
    }
}
export default (idea)