import React from "react"
class footer extends React.Component{
    render() 
    {
        return(
            <div className="container-fluid  styl-footer-row">
            <div className="row">
                <div className="col-md-12">
                    <div className="footer-heading-styling">
                        <h1>INNOV'EM</h1>
                        <h5>INNOVATE THE IDEA</h5>
                    </div>
                </div>                

                </div>
            <p className="styl-copyright"> © 2020 INNOV'EM <span className="styl-copyright-text"> ALL RIGHTS RESERVED</span></p>

        </div>




        )
    }
}
export default (footer)