import React from "react"
import {Link } from 'react-router-dom'

class Tabs extends React.Component {
   
    render() {
        return (
           <div className="styl-userProfile-Section">
               <div className="container-fluid">
                   <div className="row ">
                     <div className="col-md-12">
                     <div className="card styl-tab-card m-b-30">
            <div className="card-body">
                <ul className="nav nav-tabs tab-line" id="myTab2" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" id="home-tab2" data-toggle="tab" href="#line-home"
                           role="tab" aria-controls="home" aria-selected="true">OVERVIEW</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " id="profile-tab2" data-toggle="tab" href="#line-profile"
                           role="tab" aria-controls="profile" aria-selected="false">PRODUCTS</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " id="profile-tab2" data-toggle="tab" href="#line-profile2"
                           role="tab" aria-controls="profile" aria-selected="false">CAMPAIGN</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " id="contact-tab3" data-toggle="tab" href="#line-contact"
                           role="tab" aria-controls="contact" aria-selected="false">EMPLOYEES</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " id="contact-tab6" data-toggle="tab" href="#line-contact6"
                           role="tab" aria-controls="contact" aria-selected="false">INVESTOR</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" id="contact-tab4" data-toggle="tab" href="#line-contact4"
                           role="tab" aria-controls="contact" aria-selected="false">NEED HELP</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " id="contact-tab5" data-toggle="tab" href="#line-contact5"
                           role="tab" aria-controls="contact" aria-selected="false">HELP OTHERS</a>
                    </li>
                    
                </ul>
                <div className="tab-content" id="myTabContent2">
                    <div className="tab-pane fade show active" id="line-home" role="tabpanel"
                         aria-labelledby="home-tab">
             <iframe width="1510px" height="400px" src="https://www.youtube.com/embed/Oa9aWdcCC4o" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              
              <div className="container-fluid">
                    <div className="row styl-row-bussiness">
                      <div className="col-md-12">
                        <h1 className="styl-bussiness-heading">BUSINESS STAGE</h1> <span><i className="fas fa-edit styl-edit-bussiness"></i></span>
                        <hr className="styl-hr-bussiness" />

                        <div class="card m-b-30 styl-card-bussiness-1">
                        <div class="card-body">
                            <div class="row">
                            <div class="col-sm-2 m-b-30">
                                    <div class="option-box-grid">
                                    <input id="check1"  name="bigradios" type="checkbox" />
                                        <label for="check1">
                                            <span class="radio-content p-all-15 text-center">
                                               <img src="././assets/img/main-images/ide.png" className="wdth-progress-img" /> <br />
                                                <span class="h5 styl-h5-heading">IDEA PHASE</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-2 m-b-30">
                                    <div class="option-box-grid">
                                    <input id="check2" name="bigradios" type="checkbox" />
                                        <label for="check2">
                                            <span class="radio-content p-all-15 text-center">
                                            <img src="././assets/img/main-images/rock.png" className="wdth-progress-img" /> <br />
                                                <span class="h5 styl-h5-heading">CONCEIVED</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-2 m-b-30">
                                    <div class="option-box-grid">
                                        <input id="check3" name="bigradios" type="checkbox" />
                                        <label for="check3">
                                            <span class="radio-content p-all-15 text-center">
                                            <img src="././assets/img/main-images/prototype.png" className="wdth-progress-img" /> <br />
                                                <span class="h5 styl-h5-heading">PROTOTYPE</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-2 m-b-30">
                                    <div class="option-box-grid">
                                        <input id="check4" name="bigradios" type="checkbox" />
                                        <label for="check4">
                                            <span class="radio-content p-all-15 text-center">
                                            <img src="././assets/img/main-images/design.png" className="wdth-progress-img" /> <br />
                                                <span class="h5 styl-h5-heading">DESIGN PHASE</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-2 m-b-30">
                                    <div class="option-box-grid">
                                        <input id="check5" name="bigradios" type="checkbox" />
                                        <label for="check5">
                                            <span class="radio-content p-all-15 text-center">
                                            <img src="././assets/img/main-images/buil1.png" className="wdth-progress-img" /> <br />
                                                <span class="h5 styl-h5-heading">PRODUCT BUILT</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-2 m-b-30">
                                    <div class="option-box-grid">
                                        <input id="check6" name="bigradios" type="checkbox" />
                                        <label for="check6">
                                            <span class="radio-content p-all-15 text-center">
                                            <img src="././assets/img/main-images/card.png" className="wdth-progress-img" /> <br />
                                                <span class="h5 styl-h5-heading">PAY CUSTOMER</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                     
                      </div>  
                </div>



                        <div className="row styl-row-bussiness height-row-bussiness">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">SKILLS</h1> <span><i className="fas fa-edit styl-edit-bussiness"></i></span>
                              <hr className="styl-hr-bussiness" />
                              <div className="align-btn-skills">
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Interface</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">Product Management</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Experience</button>                      
                            </div>
                            
                           <div className="row styl-row-progress">
                            <div className="col-md-2">
                              <span className="styl-skill-span">Skill Level 60%</span>
                            <div class="progress">
                          <div class="progress-bar progress-bar-striped active" role="progressbar"
                          aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"60%"}}>
                            60%
                          </div>
                        </div>

                            </div>

                            <div className="col-md-2">
                            <span className="styl-skill-span">Skill Level 70%</span>
                            <div class="progress">
                          <div class="progress-bar progress-bar-striped active" role="progressbar"
                          aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"70%"}}>
                            70%
                          </div>
                        </div>
                            </div>
                            <div className="col-md-2">
                            <span className="styl-skill-span">Skill Level 80%</span>
   <div class="progress">
                          <div class="progress-bar progress-bar-striped active" role="progressbar"
                          aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width:"80%"}}>
                            80%
                          </div>
                        </div>
                            </div>
                           </div>


                   </div>
                </div>


                <div className="row styl-row-bussiness">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">STARTUP EXPERIENCE</h1> <span><i className="fas fa-edit styl-edit-bussiness"></i></span>
                              <hr className="styl-hr-bussiness" />
                             <h1 className="styl-product-h1">PREZI VIDEO <span className="styl-product-span">(2015-2016)</span></h1>
                             <div className="styl-product-sec">
                              <h1 className="styl-product-sec2"> PRODUCT DESIGNER</h1>
                             </div>

                             <p className="styl-content-product">
                             You and your content, together in real time<br />
                            Prezi Video is the best way to stay connected with your audience and keep them engaged.<br /> No video editing experience required.
                            Easy to use, hard to forget.<br />
                            Create impressive visuals in minutes, then jump into a video conference, or record<br /> videos to share or embed with just a few clicks.
                            More effective video conferences.<br />
                          

                             </p>
                   </div>
                </div>




                <div className="row styl-row-bussiness height-row-bussiness">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">LOOKING FOR START'UPS</h1> <span><i className="fas fa-edit styl-edit-bussiness"></i></span>
                              <hr className="styl-hr-bussiness" />
                              <div className="align-btn-skill2">
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Interface</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">Product Management</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Experience</button> 
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">Product Management</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Interface</button>
                            </div>
                   </div>
                </div>



              </div>


              <div className="row styl-row-bussiness height-row-bussiness">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">INDUSTRY INTEREST</h1> <span><i className="fas fa-edit styl-edit-bussiness"></i></span>
                              <hr className="styl-hr-bussiness" />
                              <div className="align-btn-skill2">
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Interface</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">Product Management</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Experience</button> 
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">Product Management</button>
                              <button type="button" class="btn m-b-15 ml-2 mr-2 btn-outline-success styl-btn-skills">User Interface</button>
                            </div>
                   </div>
                </div>


                <div className="row styl-row-bussiness height-row-bussiness2">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">EXPERIENCE</h1> <span><i className="fas fa-edit styl-edit-bussiness"></i></span>
                              <hr className="styl-hr-bussiness" />
                             <h1 className="styl-product-h1">UBISOFT <span className="styl-product-span">(Feburary,2015-March,2016)</span></h1>
                             <div className="styl-product-sec">
                              <h1 className="styl-product-sec2"> PRODUCT DESIGNER</h1>
                             </div>

                             <p className="styl-content-product">
                             You and your content, together in real time<br />
                            Prezi Video is the best way to stay connected with your audience and keep them engaged.<br /> No video editing experience required.
                            Easy to use, hard to forget.<br />
                            Create impressive visuals in minutes, then jump into a video conference, or record<br /> videos to share or embed with just a few clicks.
                            More effective video conferences.<br />
                             </p>
                             <hr/>
                             <h1 className="styl-product-h1">Google <span className="styl-product-span">(Feburary,2015-March,2016)</span></h1>
                             <div className="styl-product-sec">
                              <h1 className="styl-product-sec2"> PRODUCT DESIGNER</h1>
                             </div>

                             <p className="styl-content-product">
                             You and your content, together in real time<br />
                            Prezi Video is the best way to stay connected with your audience and keep them engaged.<br /> No video editing experience required.
                            Easy to use, hard to forget.<br />
                            Create impressive visuals in minutes, then jump into a video conference, or record<br /> videos to share or embed with just a few clicks.
                            More effective video conferences.<br />
                             </p>
                   </div>
                </div>
          

                <div className="row styl-row-bussiness">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">EDUCATION</h1> <span><i className="fas fa-edit styl-edit-bussiness"></i></span>
                              <hr className="styl-hr-bussiness" />
                             <h1 className="styl-product-h1">HAVARADS UNIVERSITY <span className="styl-product-span">(2009-2011)</span></h1>
                             <div className="styl-product-sec">
                              <h1 className="styl-product-sec2"> ph.D Data Sciences</h1>
                             </div>

                             <h1 className="styl-product-h1 mrgn-education-h1">YALE UNIVERSITY <span className="styl-product-span">(2005-2009)</span></h1>
                             <div className="styl-product-secc">
                              <h1 className="styl-product-sec2"> BSCS</h1>
                             </div>
                   </div>
                </div>

                <div className="row styl-row-bussiness">
                            <div className="col-md-12">
                              <h1 className="styl-bussiness-heading">CERTIFICATION</h1> <span><i className="fas fa-edit styl-edit-bussiness"></i></span>
                              <hr className="styl-hr-bussiness" />
                             <h1 className="styl-product-h1">LORAIN COUNTRY COMMUNITY COLLEGE<span className="styl-product-span">(8 Months,2014)</span></h1>
                             <div className="styl-product-secc3">
                              <h1 className="styl-product-sec2">Application Integeration</h1>
                             </div>

                             <h1 className="styl-product-h1 mrgn-education-h1">BERKLEY GLOBAL <span className="styl-product-span">(2015-2014)</span></h1>
                             <div className="styl-product-secc3">
                              <h1 className="styl-product-sec2">Application Integeration</h1>
                             </div>
                   </div>
                </div>
             
             
             
               </div>
                    <div className="tab-pane fade" id="line-profile" role="tabpanel"
                         aria-labelledby="profile-tab">
                          <div className="container-fluid">
                              <div className="row styl-product-row">
                                <div className="col-md-3">
                                <img src="./assets/img/main-images/product1.png"/>
                                </div>
                                <div className="col-md-6">
                                <h1 className="styl-Product-heading">Conversational AI and Chatbots</h1>
                                <p className="styl-product-content">
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. <br /><br />
                                They can shape customers’ opinions and their dedication to a brand. 
                                Customer service representatives are only human, so their responses to customer queries can vary.
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. 
                                </p>
                                </div>
                                <div className="col-md-2">
                                <div className="styl-product-btn">
                                <button className="product-btn1">COMMENTS <span className="product-span1">11</span></button>
                                <button className="product-btn2">UPVOTE <span className="product-span2">11</span></button>
                                <button className="product-btn3">VIEW DETAILS</button>
                                </div>

                                </div>                                
                              </div>


                              <div className="row styl-product-row mrgn-product-row">
                                <div className="col-md-3">
                                <img src="./assets/img/main-images/product2.png"/>
                                </div>
                                <div className="col-md-6">
                                <h1 className="styl-Product-heading">Conversational AI and Chatbots</h1>
                                <p className="styl-product-content">
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. <br /><br />
                                They can shape customers’ opinions and their dedication to a brand. 
                                Customer service representatives are only human, so their responses to customer queries can vary.
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. 
                                </p>
                                </div>
                                <div className="col-md-2">
                                <div className="styl-product-btn">
                                <button className="product-btn1">COMMENTS <span className="product-span1">11</span></button>
                                <button className="product-btn2">UPVOTE <span className="product-span2">11</span></button>
                                <button className="product-btn3">VIEW DETAILS</button>
                                </div>

                                </div>                                
                              </div>

                              <div className="row styl-product-row mrgn-product-row">
                                <div className="col-md-3">
                                <img src="./assets/img/main-images/product3.png"/>
                                </div>
                                <div className="col-md-6">
                                <h1 className="styl-Product-heading">Conversational AI and Chatbots</h1>
                                <p className="styl-product-content">
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. <br /><br />
                                They can shape customers’ opinions and their dedication to a brand. 
                                Customer service representatives are only human, so their responses to customer queries can vary.
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. 
                                </p>
                                </div>
                                <div className="col-md-2">
                                <div className="styl-product-btn">
                                <button className="product-btn1">COMMENTS <span className="product-span1">11</span></button>
                                <button className="product-btn2">UPVOTE <span className="product-span2">11</span></button>
                                <button className="product-btn3">VIEW DETAILS</button>
                                </div>

                                </div>                                
                              </div>


                          </div>
                    </div>

                    <div className="tab-pane fade" id="line-profile2" role="tabpanel"
                         aria-labelledby="profile-tab">
                          <div className="container-fluid">
                              <div className="row styl-product-row">
                                <div className="col-md-3">
                                <img src="./assets/img/main-images/product1.png"/>
                                </div>
                                <div className="col-md-6">
                                <h1 className="styl-Product-heading">Conversational AI and Chatbots</h1>
                                <p className="styl-product-content">
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. <br /><br />
                                They can shape customers’ opinions and their dedication to a brand. 
                                Customer service representatives are only human, so their responses to customer queries can vary.
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. 
                                </p>
                                </div>
                                <div className="col-md-2">
                                <div className="styl-product-btn">
                                <button className="product-btn1">COMMENTS <span className="product-span1">11</span></button>
                                <button className="product-btn2">UPVOTE <span className="product-span2">11</span></button>
                                <button className="product-btn3">VIEW DETAILS</button>
                                </div>

                                </div>                                
                              </div>


                              <div className="row styl-product-row mrgn-product-row">
                                <div className="col-md-3">
                                <img src="./assets/img/main-images/product2.png"/>
                                </div>
                                <div className="col-md-6">
                                <h1 className="styl-Product-heading">Conversational AI and Chatbots</h1>
                                <p className="styl-product-content">
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. <br /><br />
                                They can shape customers’ opinions and their dedication to a brand. 
                                Customer service representatives are only human, so their responses to customer queries can vary.
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. 
                                </p>
                                </div>
                                <div className="col-md-2">
                                <div className="styl-product-btn">
                                <button className="product-btn1">COMMENTS <span className="product-span1">11</span></button>
                                <button className="product-btn2">UPVOTE <span className="product-span2">11</span></button>
                                <button className="product-btn3">VIEW DETAILS</button>
                                </div>

                                </div>                                
                              </div>

                              <div className="row styl-product-row mrgn-product-row">
                                <div className="col-md-3">
                                <img src="./assets/img/main-images/product3.png"/>
                                </div>
                                <div className="col-md-6">
                                <h1 className="styl-Product-heading">Conversational AI and Chatbots</h1>
                                <p className="styl-product-content">
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. <br /><br />
                                They can shape customers’ opinions and their dedication to a brand. 
                                Customer service representatives are only human, so their responses to customer queries can vary.
                                Advances in artificial intelligence are leading to ever-more 
                                The conversations that customers have with businesses matter a lot. 
                                </p>
                                </div>
                                <div className="col-md-2">
                                <div className="styl-product-btn">
                                <button className="product-btn1">COMMENTS <span className="product-span1">11</span></button>
                                <button className="product-btn2">UPVOTE <span className="product-span2">11</span></button>
                                <button className="product-btn3">VIEW DETAILS</button>
                                </div>

                                </div>                                
                              </div>


                          </div>
                    </div>
                    <div className="tab-pane fade" id="line-contact6" role="tabpanel"
                         aria-labelledby="contact-tab">
                       <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/profile-image1.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Bob Frank</h1>
                                <h3 className="styl-nme-tag2">Designer</h3>
                                <h5 className="styl-nme-tag3">bob@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/investor" className="btn view-employee-btn">View Profile</Link>
                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/Profile-image-2.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Alveera</h1>
                                <h3 className="styl-nme-tag2">Content Writer</h3>
                                <h5 className="styl-nme-tag3">alveera@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/investor" className="btn view-employee-btn">View Profile</Link>                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/profile-image-3.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">danny</h1>
                                <h3 className="styl-nme-tag2">Programmer</h3>
                                <h5 className="styl-nme-tag3">danny@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/investor" className="btn view-employee-btn">View Profile</Link>                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/profile-image-4.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Joe</h1>
                                <h3 className="styl-nme-tag2">UI/UX Designer</h3>
                                <h5 className="styl-nme-tag3">joe@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/investor" className="btn view-employee-btn">View Profile</Link>                                </div>
                            </div>
                            </div>
                            </div>


                          </div>

                          <div className="row mrgn-row-employee-profile">
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/product-image-5.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Frank</h1>
                                <h3 className="styl-nme-tag2">Product Manager</h3>
                                <h5 className="styl-nme-tag3">frank@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/investor" className="btn view-employee-btn">View Profile</Link>                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/product-image-6.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Danny</h1>
                                <h3 className="styl-nme-tag2">Designer</h3>
                                <h5 className="styl-nme-tag3">danny@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/investor" className="btn view-employee-btn">View Profile</Link>                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/product-image-7.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Spark</h1>
                                <h3 className="styl-nme-tag2">Programmer</h3>
                                <h5 className="styl-nme-tag3">spark@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/investor" className="btn view-employee-btn">View Profile</Link> 
                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/product-image-8.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">James</h1>
                                <h3 className="styl-nme-tag2">Developer</h3>
                                <h5 className="styl-nme-tag3">james@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/investor" className="btn view-employee-btn">View Profile</Link>                                </div>
                            </div>
                            </div>
                            </div>


                          </div>
                       </div>
                    </div>

                    <div className="tab-pane fade" id="line-contact" role="tabpanel"
                         aria-labelledby="contact-tab">
                       <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/profile-image1.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Bob Frank</h1>
                                <h3 className="styl-nme-tag2">Designer</h3>
                                <h5 className="styl-nme-tag3">bob@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/employee" className="btn view-employee-btn">View Profile</Link>                                 </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/Profile-image-2.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Alveera</h1>
                                <h3 className="styl-nme-tag2">Content Writer</h3>
                                <h5 className="styl-nme-tag3">alveera@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/employee" className="btn view-employee-btn">View Profile</Link>
                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/profile-image-3.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">danny</h1>
                                <h3 className="styl-nme-tag2">Programmer</h3>
                                <h5 className="styl-nme-tag3">danny@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/employee" className="btn view-employee-btn">View Profile</Link>
                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/profile-image-4.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Joe</h1>
                                <h3 className="styl-nme-tag2">UI/UX Designer</h3>
                                <h5 className="styl-nme-tag3">joe@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/employee" className="btn view-employee-btn">View Profile</Link>
                                </div>
                            </div>
                            </div>
                            </div>


                          </div>

                          <div className="row mrgn-row-employee-profile">
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/product-image-5.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Frank</h1>
                                <h3 className="styl-nme-tag2">Product Manager</h3>
                                <h5 className="styl-nme-tag3">frank@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/employee" className="btn view-employee-btn">View Profile</Link>
                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/product-image-6.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Danny</h1>
                                <h3 className="styl-nme-tag2">Designer</h3>
                                <h5 className="styl-nme-tag3">danny@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/employee" className="btn view-employee-btn">View Profile</Link>
                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/product-image-7.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">Spark</h1>
                                <h3 className="styl-nme-tag2">Programmer</h3>
                                <h5 className="styl-nme-tag3">spark@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/employee" className="btn view-employee-btn">View Profile</Link>
                                </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="card styl-card-profile">
                            <img className="card-img-top styl-profile-img" src="./assets/img/main-images/product-image-8.png" alt="Card image cap" />
                            <div className="card-body">
                                <h1 className="styl-nme-tag">James</h1>
                                <h3 className="styl-nme-tag2">Developer</h3>
                                <h5 className="styl-nme-tag3">james@gmail.com</h5>
                                <div className="align-btn-employee">
                                <Link to="/employee" className="btn view-employee-btn">View Profile</Link>
                                </div>
                            </div>
                            </div>
                            </div>


                          </div>
                       </div>
                    </div>
                    <div className="tab-pane fade" id="line-contact4" role="tabpanel"
                        aria-labelledby="contact-tab">
                  <div id="accordion" role="tablist" aria-multiselectable="true">
    <div className="card mrgn-bttom-collapse">
        <h5 className="card-header" role="tab" id="headingOne">
            <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" className="d-block styl-colapsecard1">
                <i className="fa fa-chevron-down pull-right styl-collapse-icon"></i> HIRING
            </a>
        </h5>

        <div id="collapseOne" className="collapse show" role="tabpanel" aria-labelledby="headingOne">
        <hr />
            <div className="card-body">
                <div className="container-fluid">

                <div className="row marginBottom" >
                  <div className="col-md-4">
                      <div className="styl-collapse-row1">
                      <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                </div>

                <div className="row marginBottom" >
                  <div className="col-md-4">
                      <div className="styl-collapse-row1">
                      <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                </div>

                <div className="row marginBottom" >
                  <div className="col-md-4">
                      <div className="styl-collapse-row1">
                      <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                </div>

   
              
                </div>

                

                
          
           
            </div>
        </div>
    </div>
    <div className="card mrgn-bttom-collapse">
        <h5 className="card-header" role="tab" id="headingTwo">
            <a className="collapsed d-block styl-colapsecard1" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <i className="fa fa-chevron-down pull-right styl-collapse-icon"></i> MENTOR
            </a>
        </h5>
        <div id="collapseTwo" className="collapse" role="tabpanel" aria-labelledby="headingTwo">
            <div className="card-body">
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
                proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
                aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
            </div>
        </div>
    </div>
    <div className="card mrgn-bttom-collapse">
        <h5 className="card-header" role="tab" id="headingThree">
            <a className="collapsed d-block styl-colapsecard1" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <i className="fa fa-chevron-down pull-right styl-collapse-icon"></i> SPEAK AT EVENT
            </a>
        </h5>
        <div id="collapseThree" className="collapse" role="tabpanel" aria-labelledby="headingThree">
            <div className="card-body">
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
                proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
                aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
            </div>
        </div>
    </div>
</div>
                    </div>
                    <div className="tab-pane fade" id="line-contact5" role="tabpanel"
                         aria-labelledby="contact-tab">
                                 <div id="accordion" role="tablist" aria-multiselectable="true">
    <div className="card mrgn-bttom-collapse">
        <h5 className="card-header" role="tab" id="headingOne">
            <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne" className="d-block styl-colapsecard1">
                <i className="fa fa-chevron-down pull-right styl-collapse-icon"></i> HIRING
            </a>
        </h5>

        <div id="collapseOne" className="collapse show" role="tabpanel" aria-labelledby="headingOne">
        <hr />
            <div className="card-body">
                <div className="container-fluid">

                <div className="row marginBottom" >
                  <div className="col-md-4">
                      <div className="styl-collapse-row1">
                      <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                </div>

                <div className="row marginBottom" >
                  <div className="col-md-4">
                      <div className="styl-collapse-row1">
                      <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                </div>

                <div className="row marginBottom" >
                  <div className="col-md-4">
                      <div className="styl-collapse-row1">
                      <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                  <div className="col-md-4">
                  <div className="styl-collapse-row1">
                  <div className="row">
                     <div className="col-md-6">
                          <h1 className="styl-inner-collapse-heading">Data Entry Administrator</h1>
                     </div>
                     <div className="col-md-6">
                       <div className="sty-inner-collapse-icon">
                       <i class="fas fa-eye styl-inner-icon"></i>
                       <i class="fas fa-edit styl-inner-icon"></i>
                       <i class="fas fa-trash styl-inner-icon"></i>
                       </div>
                     </div>

                   </div>

                   <div className="align-inner-icon2">
                    <i class="fas fa-university styl-icons-bnk1"></i>   <span className="styl-span-bnk">Banking</span>
                     <i class="fas fa-clock styl-icons-bnk2"></i> <span className="styl-span-bnk">Full Time</span>
                    </div> 

                    <div className="align-location-icon">
                    <i class="fas fa-location-arrow sty-inner-icon2"></i> <span className="styl-span-location">Tornoto,Canada</span>
                    </div>
                    
                    <hr />

                    <div className="align-inner-icon3">
                    <i class="fas fa-money-check-alt styl-icons-bnkk2"></i>   <span className="styl-span-bnk2">Salary:<b className="styl-b-tag">$4500-$5000</b></span>
                    <i class="fab fa-empire sty-inner-icon3"></i> <span className="styl-span-bnk2">Expiry:<b className="styl-b-tag">31-03-2020</b></span>
                    </div> 
                    
                      </div>
                  </div>
                </div>

   
              
                </div>

                

                
          
           
            </div>
        </div>
    </div>
    <div className="card mrgn-bttom-collapse">
        <h5 className="card-header" role="tab" id="headingTwo">
            <a className="collapsed d-block styl-colapsecard1" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <i className="fa fa-chevron-down pull-right styl-collapse-icon"></i> MENTOR
            </a>
        </h5>
        <div id="collapseTwo" className="collapse" role="tabpanel" aria-labelledby="headingTwo">
            <div className="card-body">
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
                proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
                aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
            </div>
        </div>
    </div>
    <div className="card mrgn-bttom-collapse">
        <h5 className="card-header" role="tab" id="headingThree">
            <a className="collapsed d-block styl-colapsecard1" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <i className="fa fa-chevron-down pull-right styl-collapse-icon"></i> SPEAK AT EVENT
            </a>
        </h5>
        <div id="collapseThree" className="collapse" role="tabpanel" aria-labelledby="headingThree">
            <div className="card-body">
                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf
                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod.
                Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda
                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea
                proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim
                aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
            </div>
        </div>
    </div>
</div>
                    </div>

                </div>
            </div>
            </div>
            {/* End of Modals */}
                     </div>
                   </div>
               </div>
           </div>
        );
    }
}

export default Tabs;